/* eslint-disable no-useless-concat */
// eslint-disable-next-line import/prefer-default-export
export const tanningHero = {
  title: "Tanning & beauty salon point-of-sale system",
  subtext:
    "Book more appointments, keep clients coming back as loyal regulars, and get paid faster with simple tech that’s easy for you and your clients.",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/health-and-beauty/tanning-salon/demo",
  },
};
export const tanningSubHero = {
  title: "Everything your tanning salon needs to stand out",
  list: [
    "Increase sales by booking more appointments ",
    "Attract new clients with a strong online presence",
    "Improve client retention and drive revenue growth",
    "Put more money in your pocket with more ways to get paid",
  ],
  subtext:
    "Managing appointments and team schedules while marketing your business to attract new clients is a never-ending effort. We can help you streamline operations and connect with clients with an end-to-end technology platform designed to save you time and increase revenue.",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/health-and-beauty/tanning-salon/demo",
  },
};

export const tanningWhiteGlove = {
  mainTitle: "Personalized service & support",
  title: "Work with a team that cares about your success",
  content:
    "You work hard to give your clients the best service. At SpotOn, we do the same. Our team will work closely with you to customize a software solution that makes sense for your tanning salon. And we’ll continue to be there for you with local service and 24/7 technical support.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/health-and-beauty/tanning-salon/demo",
  },
};

export const tanningLargeFeatureA = {
  featureBlocks: [
    {
      blockTitle: "Save time and money while increasing sales",
      blockSubTitle:
        "Say goodbye to mismatched software, your clunky old credit card terminal, and the geek who takes forever to update your website. SpotOn simplifies how you do business with technology that’s powerful yet easy to use. Our cloud-based tanning salon software is thoughtfully designed to streamline numerous time-consuming tasks, while creating a seamless client experience that will help you build your brand and grow your business.",
      blockImg: "dentist-a.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/health-and-beauty/tanning-salon/demo",
      },
    },
  ],
};

export const tanningTrusted = [
  {
    title: "30 – 40%",
    subtext: "Increase in sales with online booking",
  },
  {
    title: "42%",
    subtext: "Clients prefer to schedule online",
  },
  {
    title: "31%",
    subtext: "Increase in sales with repeat clients",
  },
  {
    title: "40%",
    subtext: "decrease in no-shows with automated reminders",
  },
];

export const tanningMultiSection = {
  sectionTitle: "More clients. Less hassle",
  featureBlocks: [
    {
      blockTitle: "Book more appointments & reduce no-shows",
      blockSubTitle:
        "Give your clients what they want by enabling them to schedule appointments online anytime, right from your website and Facebook page. Simultaneously, save time managing staff schedules and repeat bookings, and reduce no-shows with automated appointment reminders. ",
      blockList: [
        "Online booking",
        "Interactive calendaring",
        "Text & email reminders",
        "No-show protection",
        "Mailing list builder",
      ],
      blockImg: "tanning-a.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/health-and-beauty/tanning-salon/demo",
      },
    },
    {
      blockTitle: "Improve client loyalty. And revenue",
      blockSubTitle:
        "Increase sales with the digital loyalty rewards program that’s easy to set up and designed to keep your clients coming back again and again.",
      blockList: [
        "Create custom rewards",
        "Enroll customers during checkout",
        "Increase sales with each check-in & redemption",
      ],
      blockImg: "tanning-b.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/health-and-beauty/tanning-salon/demo",
      },
    },
    {
      blockTitle: "Attract new clients with a stunning new website",
      blockSubTitle:
        "Help your tanning salon get discovered by more clients online with a slick new website that’s optimized to show up higher in Google searches. Our team of experts will build it for you at a do-it-yourself price. ",
      blockImg: "tanning-c.png",
      blockList: [
        "Custom website design",
        "Web hosting",
        "Online appointment booking",
        "Self-service dashboard",
        "Optional online store",
        "Lifetime support",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/health-and-beauty/tanning-salon/demo",
      },
    },
    {
      blockTitle: "Market like a pro",
      blockSubTitle:
        "With SpotOn Marketing, it’s easier than ever before to stay in touch with clients, whether it’s to announce new services, offer discounts, or simply say thank you. You’ll be able to send branded emails and deals quickly and easily to every client who has booked an appointment or enrolled in your loyalty program.",
      blockImg: "tanning-d.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/health-and-beauty/tanning-salon/demo",
      },
    },
    {
      blockTitle: "Upgrade the payment experience with a sleek handheld device",
      blockSubTitle:
        "SpotOn Terminal makes the checkout process easy for you and your clients. And with full Marketing and Loyalty integration, your clients can redeem digital coupons and loyalty rewards at the same time they’re paying—leading to increased sales for your tanning salon.",
      blockList: [
        "Apple Pay, Google Pay, and NFC enabled",
        "Wi-Fi and 4G connectivity",
        "Built-in receipt printer and scanner",
        "Customer email capture to grow your mailing list",
        "Cash discount and surcharge ready",
      ],
      blockImg: "tanning-e.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/health-and-beauty/tanning-salon/demo",
      },
    },
  ],
};

export const tanningCTA = {
  title: "Sign up for your consultation.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/health-and-beauty/tanning-salon/demo",
  },
};

export const tanningTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const tanningArticles = {
  title: "Expert articles & advice on growing your tanning salon",
  bgText: "Articles.",
};

export const hairCar = {
  // Uncomment the following line and remove
  // the other title once we add more than one vertical
  // title: 'We know health & beauty',
  title: "We know health & beauty",
  subtext:
    "Our appointment and scheduling software is great for all kinds of salons, spas, and health & beauty providers.",
  slides: [
    {
      bg: "hair-salon.png",
      cardTitle: "Hair salon",
      cardContent: "More appointments, fewer no-shows",
      icon: "hair-salon.png",
      linkTo: "/health-and-beauty/hair-salons",
    },
    {
      bg: "nail-salon.png",
      cardTitle: "Nail salons",
      cardContent: "Increase sales and get paid fast",
      icon: "nail-salon.png",
      linkTo: "/health-and-beauty/nail-salons",
    },
    {
      bg: "barber.png",
      cardTitle: "Barbershop",
      cardContent: "Increase sales and get paid fast",
      icon: "barber.png",
      linkTo: "/health-and-beauty/barber",
    },
    {
      bg: "dentist.png",
      cardTitle: "Dentists",
      cardContent: "Increase sales and get paid fast",
      icon: "dentist.png",
      linkTo: "/health-and-beauty/dentist",
    },
  ],
};
