import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import BHero from "../../../components/Hero/b-hero";
import {
  tanningHero,
  tanningSubHero,
  tanningWhiteGlove,
  tanningLargeFeatureA,
  tanningTrusted,
  tanningMultiSection,
  tanningCTA,
  tanningTestimonials,
  tanningArticles,
  // tanningFaqs,
  hairCar,
} from "../../../data/subverticals/health-and-beauty/tanning";
import { hbBTSlides, heroSuccess } from "../../../data/success-stories-data";
// import NoVisualsCustom from "../../../components/NoVisuals/NoVisualsCustom";
// import WhiteGlove from "../../../components/WhiteGlove/WhiteGlove";
// import LargeFeatures from "../../../components/LargeFeatures/LargeFeatures";
// import TrustedNumbers from "../../../components/TrustedNumbers/TrustedNumbers";
// import LargeCta from "../../../components/CTAs/LargeCta";
// import SuccessStories from "../../../components/SuccessStories/SuccessStories";
// import TestmonialReviews from "../../../components/TestimonialReviews/TestmonialReviews";
// import BusinessTypes from "../../../components/BusinessTypes/BusinessTypes";
// import Articles from "../../../components/Articles/Articles";

import heroBg from "../../../images/hero-images/hair-salon-white-glove.png";

const NoVisualsCustom = loadable(() =>
  import("../../../components/NoVisuals/NoVisualsCustom")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const TrustedNumbers = loadable(() =>
  import("../../../components/TrustedNumbers/TrustedNumbers")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const SuccessStories = loadable(() =>
  import("../../../components/SuccessStories/SuccessStories")
);
const TestmonialReviews = loadable(() =>
  import("../../../components/TestimonialReviews/TestmonialReviews")
);
const BusinessTypes = loadable(() =>
  import("../../../components/BusinessTypes/BusinessTypes")
);
const Articles = loadable(() =>
  import("../../../components/Articles/Articles")
);

const whiteGloveImport = "tanning-white-glove.png";

const tannings = () => {
  return (
    <Layout>
      <SEO
        title="Tanning & Beauty Salon Point of Sale (POS) System | SpotOn"
        description="Our POS software is made for tanning & beauty salons. Easy to use POS with flexible payment options, appointment booking, marketing, and loyalty solutions. Get a demo!"
        image={`https://spoton.com/${heroBg}`}
        cannonical="https://www.spoton.com/health-and-beauty/tanning-salon/"
      />
      <BHero sectionData={tanningHero} heroBg="tanning-hero.png" />
      <NoVisualsCustom sectionData={tanningSubHero} complex />
      <WhiteGlove
        sectionData={tanningWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeFeatures
        sectionData={tanningLargeFeatureA}
        style={{ marginTop: "128px" }}
      />
      <TrustedNumbers title="By the numbers" numbersArray={tanningTrusted} />
      <LargeFeatures
        sectionData={tanningMultiSection}
        style={{ marginTop: "128px !important" }}
      />
      <LargeCta sectionData={tanningCTA} />
      <SuccessStories sectionData={heroSuccess} slides={hbBTSlides} />
      <TestmonialReviews sectionData={tanningTestimonials} />
      <Articles
        sectionData={tanningArticles}
        blogFilters={{
          limit: 3,
          include: "tags,authors",
          filter: "tag:small-business",
        }}
        cornerStone={false}
        style={{ marginTop: 60 }}
      />
      <BusinessTypes sectionData={hairCar} />
      {/* <Faqs sectionData={tanningFaqs} /> */}
    </Layout>
  );
};

export default tannings;
